<template>
  <SidebarMenu v-model:rtl="asideRtlMode" v-model:collapsed="asdieCollapsed" :menu="menu" :width="asdieCollapsed ? '300px' : '200px'" @item-click="itemClick">
    <template #header>
      <button class="vsm--toggle-btn vsm--toggle_direction" aria-label="direction" @click="toggleRtlMode()">
        <Icon v-tooltip.bottom="`${asideRtlMode ? '왼' : '오른'}쪽 배치`" :name="btnRtlIcon" size="20" />
      </button>
    </template>
    <template #toggle-icon>
      <Icon v-tooltip.top="`메뉴 ${asdieCollapsed ? '보이' : '숨기'}기`" :name="btnCollapsedIcon" size="15" />
    </template>
  </SidebarMenu>
</template>

<script setup>
import { SidebarMenu } from 'vue-sidebar-menu';

// 레이아웃 정보
const layoutStore = useLayoutStore();
const { asdieCollapsed, asideRtlMode } = storeToRefs(layoutStore);

// aside 메뉴 rtl 모드 설정
const toggleRtlMode = useToggle(asideRtlMode);
const btnRtlIcon = computed(() => (asideRtlMode.value ? 'streamline:move-left' : 'streamline:move-right'));

const btnCollapsedIcon = computed(() =>
  asdieCollapsed.value
    ? asideRtlMode.value
      ? 'mdi:arrow-collapse-left'
      : 'mdi:arrow-expand-right'
    : asideRtlMode.value
      ? 'mdi:arrow-collapse-right'
      : 'mdi:arrow-expand-left',
);

// 패널정보(패널추가)
const { createPanel } = useWindowStore();

// 메뉴정보
// const menu = useMenuList();

// 개발가이드 추가를 위한 임시메뉴
const _menu = useMenuList();
const isLocalhost = location.hostname === 'localhost';
const menu = computed(() => {
  return [
    ..._menu.value,
    isLocalhost
      ? {
          id: 'DEV',
          type: 'menu',
          title: '개발가이드',
          icon: {
            element: 'AppAsideIcon',
            attributes: { name: 'mdi:dev-to' },
          },
          child: [
            {
              id: 'ZZZZZ',
              type: 'screen',
              title: '색상표',
            },
          ],
        }
      : {},
  ];
});

function itemClick(event, menu) {
  const { type } = menu;
  if (type === 'screen') {
    createPanel(menu);
  }
}
</script>
